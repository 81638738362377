<template>
  <div class="secPageTopBox mb-only">
      <div class="btn-go-back "><a href="#" @click.prevent="goback">이전페이지</a></div>
      <div class="sub-mb-title">
          {{ title }}
      </div>
  </div>
</template>
<script>

export default {
  name: "mbHeaderTitle",
  data(){
    return { 
    }
  }, 
  props: {
    title: {
      type: String,
      required: false, 
    }, 
  },
  methods:{ 
    goback(){
      this.$router.go(-1);
    }
  }
}
</script>